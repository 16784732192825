import React, {Component, PropTypes} from 'react';

export default class ThunderSynthesisPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div className='iframe-holder'>
                <iframe
                    width='100%'
                    height='100%'
                    seamless='seamless'
                    frameBorder='0'
                    src='https://medium.com/@othnielcundangan/mumt-307-project-thunder-synthesis-in-unity-5274643f22e7'
                    className='sonification-container'
                />
            </div>
        );
    }
}