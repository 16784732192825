import React, {Component, PropTypes} from 'react';

export default class ThisSitePage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <iframe
                width='100%'
                height='100'
                seamless='seamless'
                frameBorder='0'
                src='http://mcgillscheduler.tk'
                className='sonification-container'
            />
        );
    }
}
