import React, {Component, PropTypes} from 'react';
import {browserHistory} from 'react-router';

import Sidebar from '../components/Sidebar.jsx';

const pageTitles = {
    // '/portfolio': 'Portfolio',
    '/portfolio/work': 'Work Experience',
    '/portfolio/work/globalvision': 'GlobalVision',
    '/portfolio/projects': 'Developer Experience',
    '/portfolio/projects/2': 'Developer Experience',
    '/portfolio/projects/thunder-synthesis': 'Thunder Synthesis',
    '/portfolio/projects/image-sonification': 'Image Sonification',
    '/portfolio/projects/boids-flocking': 'Boids',
    '/portfolio/projects/mcgill-once-mcgill-twice': 'McGill Once McGill Twice',
    '/portfolio/projects/ubisoft-competition': 'Ubisoft Competition',
    '/portfolio/projects/mclab-web': 'McLab-Web',
    '/portfolio/projects/mcgill-scheduler': 'McGill Scheduler',
    '/portfolio/education': 'Educational Experience',
    '/portfolio/education/computer-graphics-course': 'Computer Graphics',
    '/portfolio/education/computer-animation-course': 'Computer Animation',
    '/portfolio/education/artificial-intelligence-course': 'Artificial Intelligence',
    '/resume': 'Resume',
    '/contact': 'Contact Me',
    '/contact/sent': 'Message Sent',
};

export default class App extends Component {
    static propTypes = {
        children: PropTypes.node,
        location: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.navigatePage = this.navigatePage.bind(this);

        // Determine which page the browser loaded
        const pathArray = props.location.pathname.split('/');
        const route = '/' + pathArray.slice(1).join('/');
        this.state = {
            page: route,
            pageTitle: pageTitles[route],
        };
    }

    navigatePage(route) {
        browserHistory.push(route);
        const page = route;
        const pageTitle = pageTitles[route];
        this.setState({page, pageTitle});
    }

    componentWillReceiveProps(props) {
        const pathArray = props.location.pathname.split('/');
        const route = '/' + pathArray.slice(1).join('/');
        this.setState({
            page: route,
            pageTitle: pageTitles[route]
        });
    }

    componentDidMount() {
        window.appInstance = this;
    }

    render() {
        const {children} = this.props;

        return (
            <div>
                <Sidebar
                    ref={(sidebar) => this.sidebar = sidebar}
                    app={this}
                />

                {/*<!-- !PAGE CONTENT! -->*/}
                <div className="w3-main" style={{marginLeft:'300px',height:'100vh'}}>

                    {/*<!-- Header -->*/}
                    <header className="w3-container" id="portfolio">
                        <a>
                            <img
                                /*src="/static/images/avatar_g2.jpg"*/
                                /*src="https://media.licdn.com/mpr/mpr/shrinknp_400_400/AAEAAQAAAAAAAAcXAAAAJGY5ODE4ZDExLThkMmYtNGZmMi1hMzQ5LTBmOTMzZWM3NzI5ZQ.jpg"*/
                                src="/static/images/profpic.jpg"
                                style={{width:'65px'}}
                                className="w3-circle w3-right w3-margin w3-hide-large w3-hover-opacity"
                            />
                        </a>
                        <span className="w3-opennav w3-hide-large w3-xxlarge w3-hover-text-grey" onClick={() => this.sidebar.open()}><i className="fa fa-bars"/></span>
                        <h1><b>{this.state.pageTitle}</b></h1>
                        <div className="w3-section w3-bottombar"/>
                    </header>

                    {/* Render children */}
                    {React.cloneElement(children, {app: this})}

                    {/*<!-- Footer -->*/}
                    {/*
                    <footer className="w3-container w3-padding-32 w3-dark-grey">
                        <div className="w3-row-padding">
                            <div className="w3-third">
                                <h3>FOOTER</h3>
                                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus
                                    vitae, ultricies congue gravida diam non fringilla.</p>
                            </div>

                            <div className="w3-third">
                                <h3>BLOG POSTS</h3>
                                <ul className="w3-ul w3-hoverable">
                                    <li className="w3-padding-16">
                                        <img src="/static/images/workshop.jpg"
                                             className="w3-left w3-margin-right" style={{width: '50px'}}/>
                                        <span className="w3-large">Lorem</span><br/>
                                        <span>Sed mattis nunc</span>
                                    </li>
                                    <li className="w3-padding-16">
                                        <img src="/static/images/gondol.jpg"
                                             className="w3-left w3-margin-right" style={{width: '50px'}}/>
                                        <span className="w3-large">Ipsum</span><br/>
                                        <span>Praes tinci sed</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="w3-third">
                                <h3>POPULAR TAGS</h3>
                                <p>
                                    <span className="w3-tag w3-black w3-margin-bottom">Travel</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">New York</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">London</span>
                                    <span className="w3-tag w3-grey w3-small w3-margin-bottom">IKEA</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">NORWAY</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">DIY</span>
                                    <span className="w3-tag w3-grey w3-small w3-margin-bottom">Ideas</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">Baby</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">Family</span>
                                    <span className="w3-tag w3-grey w3-small w3-margin-bottom">News</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">Clothing</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">Shopping</span>
                                    <span className="w3-tag w3-grey w3-small w3-margin-bottom">Sports</span> <span
                                    className="w3-tag w3-grey w3-small w3-margin-bottom">Games</span>
                                </p>
                            </div>

                        </div>
                    </footer>
                    */}
                </div>
            </div>
        );
    }
}
