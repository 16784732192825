import React, {Component, PropTypes} from 'react';

export default class ContactSentPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div className='contact-sent-body'>
                <h3>Thank you, your message was successfully sent!</h3>
            </div>
        );
    }
}
