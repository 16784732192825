import React, {Component, PropTypes} from 'react';

import PortfolioCard from '../components/PortfolioCard.jsx';

export default class DeveloperExperiencePage extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children, app} = this.props;

        return (
            <div>
                {/*<!-- First Photo Grid-->*/}
                <div className="w3-row-padding portfolio-row">
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/thunder.png'
                        /*route='/portfolio/projects/thunder-synthesis'*/
                        url='https://medium.com/@othnielcundangan/mumt-307-project-thunder-synthesis-in-unity-5274643f22e7'
                    >
                        <p><b>Thunder Synthesis</b></p>
                        <p>Procedural thunder audio effect using Stk in C++ and Unity.</p>
                    </PortfolioCard>

                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/sonification.jpg'
                        route='/portfolio/projects/image-sonification'
                    >
                        <p><b>Image Sonification</b></p>
                        <p>Conversion of images to sound using fourier transforms.</p>
                        <div
                            style={{
                                position: 'absolute',
                                bottom:'4px',
                                right:'6px',
                                left: '6px',
                                fontSize:'0.5em',
                                textAlign: 'right',
                                wordWrap: 'break-word'
                            }}
                        >
                            Image from <a href="http://kingsounds.net/uploads/images/SoundwavesauiDetail.png" target="_blank">kingsounds.net/uploads/images/SoundwavesauiDetail.png</a>
                        </div>
                    </PortfolioCard>

                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/ubisoft.png'
                        route='/portfolio/projects/ubisoft-competition'
                    >
                        <p><b>Ubisoft Competition</b></p>
                        <p>An annual inter-university game development competition held by Ubisoft Montreal.</p>
                    </PortfolioCard>
                </div>

                {/*<!-- Second Photo Grid-->*/}
                <div className="w3-row-padding portfolio-row">
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/mclabweb.png'
                        route='/portfolio/projects/mclab-web'
                    >
                        <p><b>McLab Web</b></p>
                        <p>A web-based offering of McGill's McLab Project's suite of MATLAB tools.</p>
                    </PortfolioCard>

                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/mcgill-scheduler.png'
                        route='/portfolio/projects/mcgill-scheduler'
                    >
                        <p><b>McGill Scheduler</b></p>
                        <p>A web app built with MeteorJS to help students locate their courses and clubs on a map.</p>
                    </PortfolioCard>

                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/boids.jpg'
                        route='/portfolio/projects/boids-flocking'
                    >
                        <p><b>Boids Flocking</b></p>
                        <p>Accelerated real-time simulations of flocks which are based on autonomous "boids" with steering behaviours.</p>
                    </PortfolioCard>
                </div>

                {/*<!-- Pagination -->*/}
                <div className="w3-center w3-padding-32">
                    <ul className="w3-pagination">
                        <li><a className="w3-black">1</a></li>
                        <li><a className="w3-hover-black" style={{cursor:'pointer'}} onClick={() => app.navigatePage('/portfolio/projects/2')}>2</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}
