import React, {Component, PropTypes} from 'react';

export default class Sidebar extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    open() {
        this.sidebar.style.display = 'block';
        this.sidebarOverlay.style.display = 'block';
    }

    close() {
        this.sidebar.style.display = 'none';
        this.sidebarOverlay.style.display = 'none';
    }

    render() {
        const {children, app} = this.props;

        return (
            <div>
                <nav
                    ref={(sidebar) => this.sidebar = sidebar}
                    className="w3-sidenav w3-collapse w3-white w3-animate-left"
                    style={{
                        zIndex: 3,
                        width: '300px',
                        display: 'none'
                    }}
                >
                    <br/>
                    <div className="w3-container">
                        <a
                            onClick={this.close}
                            className="w3-hide-large w3-right w3-jumbo w3-padding"
                            title="close menu"
                        >
                            <i className="fa fa-remove"/>
                        </a>
                        <img
                            /*src="/static/images/avatar_g2.jpg"*/
                            /*src="https://media.licdn.com/mpr/mpr/shrinknp_400_400/AAEAAQAAAAAAAAcXAAAAJGY5ODE4ZDExLThkMmYtNGZmMi1hMzQ5LTBmOTMzZWM3NzI5ZQ.jpg"*/
                            src="/static/images/profpic.jpg"
                            style={{width:'45%'}}
                            className="w3-round"
                        />
                        <br/>
                        <br/>
                        <h4 className="w3-padding-0">
                            <b>Othniel Cundangan</b>
                        </h4>
                    </div>
                    {/*<a
                        onClick={() => {app.navigatePage('/portfolio'); this.close();}}
                        className={`w3-padding ${app.state.page.split('/').slice(0,2).join('/') === '/portfolio' ? 'w3-text-teal' : ''}`}
                    >
                        <i className="fa fa-th-large fa-fw w3-margin-right"/>
                        PORTFOLIO
                    </a>*/}
                    <a
                        onClick={() => {app.navigatePage('/portfolio/work'); this.close();}}
                        className={`w3-padding ${app.state.page.split('/').slice(0,3).join('/') === '/portfolio/work' ? 'w3-text-teal' : ''}`}
                    >
                        <i className="fa fa-briefcase fa-fw w3-margin-right"/>
                        WORK
                    </a>
                    <a
                        onClick={() => {app.navigatePage('/portfolio/projects'); this.close();}}
                        className={`w3-padding ${app.state.page.split('/').slice(0,3).join('/') === '/portfolio/projects' ? 'w3-text-teal' : ''}`}
                    >
                        <i className="fa fa-gamepad fa-fw w3-margin-right"/>
                        PROJECTS
                    </a>
                    <a
                        onClick={() => {app.navigatePage('/portfolio/education'); this.close();}}
                        className={`w3-padding ${app.state.page.split('/').slice(0,3).join('/') === '/portfolio/education' ? 'w3-text-teal' : ''}`}
                    >
                        <i className="fa fa-graduation-cap fa-fw w3-margin-right"/>
                        MCGILL EDUCATION
                    </a>
                    <a
                        onClick={() => {app.navigatePage('/resume'); this.close();}}
                        className={`w3-padding ${app.state.page.split('/').slice(0,2).join('/') === '/resume' ? 'w3-text-teal' : ''}`}
                    >
                        <i className="fa fa-file-text fa-fw w3-margin-right"/>
                        RESUME
                    </a>

                    <div className="w3-section w3-padding-top w3-large">
                        {/*<a className="w3-hover-white w3-hover-text-indigo w3-show-inline-block"><i className="fa fa-facebook-official"/></a>*/}
                        {/*<a className="w3-hover-white w3-hover-text-purple w3-show-inline-block"><i className="fa fa-instagram"/></a>*/}
                        {/*<a className="w3-hover-white w3-hover-text-yellow w3-show-inline-block"><i className="fa fa-snapchat"/></a>*/}
                        {/*<a className="w3-hover-white w3-hover-text-red w3-show-inline-block"><i className="fa fa-pinterest-p"/></a>*/}
                        {/*<a className="w3-hover-white w3-hover-text-light-blue w3-show-inline-block"><i className="fa fa-twitter"/></a>*/}
                        <a href="https://ca.linkedin.com/in/othnielcundangan" target="_blank" className="w3-hover-white w3-hover-text-indigo w3-show-inline-block"><i className="fa fa-linkedin"/></a>
                        <a href="https://github.com/oatssss" target="_blank" className="w3-hover-white w3-hover-text-indigo w3-show-inline-block"><i className="fa fa-github"/></a>
                    </div>
                </nav>

                {/*<!-- Overlay effect when opening sidenav on small screens -->*/}
                <div
                    ref={(sidebarOverlay) => this.sidebarOverlay = sidebarOverlay}
                    className="w3-overlay w3-hide-large w3-animate-opacity"
                    onClick={this.close}
                    style={{
                        cursor: 'pointer',
                        display: 'none'
                    }}
                    title="close side menu"
                />
            </div>
        );
    }
}
