import React, {Component, PropTypes} from 'react';

import PortfolioCard from '../components/PortfolioCard.jsx';

export default class DeveloperExperiencePage extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children, app} = this.props;

        return (
            <div>
                {/*<!-- First Photo Grid-->*/}
                <div className="w3-row-padding portfolio-row">
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/momt.png'
                        route='/portfolio/projects/mcgill-once-mcgill-twice'
                    >
                        <p><b>McGill Once McGill Twice</b></p>
                        <p>University campus multi-player RPG.</p>
                    </PortfolioCard>

                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/portfolio.png'
                        /*route='/portfolio/projects/site'*/
                    >
                        <p><b>This Portfolio Site</b></p>
                        <p>Made with Node, Express, React, and W3 Templates.</p>
                    </PortfolioCard>
                </div>

                {/*<!-- Pagination -->*/}
                <div className="w3-center w3-padding-32">
                    <ul className="w3-pagination">
                        <li><a className="w3-hover-black" style={{cursor:'pointer'}} onClick={() => app.navigatePage('/portfolio/projects')}>1</a></li>
                        <li><a className="w3-black" >2</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}
