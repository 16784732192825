import React, {Component, PropTypes} from 'react';

export default class BoidsFlockingPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>Video Demo</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/7qt_t4Z7O7M?rel=0" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Overview</h3>
                <p className='portfolio-indented'>
                    This project aimed to produce autonomous entities that flock together in a realistic manner for use within video games.
                    The project was based on steering behaviours as proposed by Craig Reynolds in his <a href="http://www.cs.toronto.edu/~dt/siggraph97-course/cwr87/" target="_blank">technical paper from SIGGRAPH '87</a>.
                    Additionally, it explored methods of accelerating real-time simulation and also introduced interactive components to the flock.
                </p>

                <h3>Results</h3>
                <p className='portfolio-indented'>
                    In an attempt to accelerate real-time simulation,
                    spatial data structures such as kd-trees and bins/hashes were tested and benchmarked.
                    You may download a report of the research and results <a href="boids_flocking_report.pdf" target="_blank">here</a>.
                </p>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://unity3d.com/" target="_blank">Unity3D</a><br/>
                    <a href="https://msdn.microsoft.com/en-ca/library/2fdbz5xd.aspx" target="_blank">C# Programming Language</a><br/>
                    <a href="https://www.blender.org/" target="_blank">Blender3D</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                    <a href="https://www.latex-project.org/" target="_blank">LaTeX</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/Boids-Flocking/tree/master/Boids%20Flocking/Assets/Scripts/Boids" target="_blank">View the relevant files within the GitHub repo</a><br/>
                    <a href="/static/docs/simulated-flocking-game.pdf" target="_blank">View the report</a><br/>
                    <a href="http://www.red3d.com/cwr/boids/" target="_blank">View Craig Reynolds' steering behaviours</a>
                </p>
            </div>
        );
    }
}
