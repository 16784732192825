import React, {Component, PropTypes} from 'react';

export default class Four04Page extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h1>Sorry, that page doesn't exist</h1>
            </div>
        );
    }
}
