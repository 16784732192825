import React, {Component, PropTypes} from 'react';

import PortfolioCard from '../components/PortfolioCard.jsx';

export default class EducationExperiencePage extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children, app} = this.props;

        return (
            <div>
                <h6 style={{
                    textAlign:'center',
                    textTransform:'uppercase',
                    fontSize:'0.85em'
                }}>
                    <b>Only the courses with deliverables with significant user interaction are displayed here</b>
                </h6>
                {/*<!-- First Photo Grid-->*/}
                <div className="w3-row-padding portfolio-row">
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/557.png'
                        route='/portfolio/education/computer-graphics-course'
                    >
                        <p><b>Computer Graphics Course</b></p>
                        <p>Matrix transformations, the OpenGL pipeline, shaders, ray tracing, splines... you name it.</p>
                    </PortfolioCard>
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/559.png'
                        route='/portfolio/education/computer-animation-course'
                    >
                        <p><b>Computer Animation Course</b></p>
                        <p>Mass-spring particle systems, rigid bodies, fluid simulations.</p>
                    </PortfolioCard>
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/424.png'
                        route='/portfolio/education/artificial-intelligence-course'
                    >
                        <p><b>Artificial Intelligence Course</b></p>
                        <p>Heuristic search, constraint satisfaction, game playing, hidden Markov models, machine learning.</p>
                    </PortfolioCard>
                </div>

                {/*<!-- Pagination -->*/}
                {/*
                <div className="w3-center w3-padding-32">
                    <ul className="w3-pagination">
                        <li><a className="w3-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">1</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">2</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">3</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">4</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">»</a></li>
                    </ul>
                </div>
                */}
            </div>
        );
    }
}
