import React from 'react';
import Validation from 'react-validation';
const emailScheme = require('./simple-schemas.js').emailScheme;

Object.assign(Validation.rules, {
    // Key name maps the rule
    required: {
        // Function to validate value
        // NOTE: value might be a number -> force to string
        rule: value => {
            return value.toString().trim();
        },
        // Function to return hint
        // You may use current value to inject it in some way to the hint
        hint: value => {
            return <span className='react-validation-error-text'>Required</span>
        }
    },
    email: {
        // Example usage with external 'validator'
        rule: value => {
            const valid = emailScheme.newContext().validate({email:value});
            return valid;
        },
        hint: value => {
            return <span className='react-validation-error-text'>Unable to send emails to {value}</span>
        }
    },
    // This example shows a way to handle common task - compare two fields for equality
    password: {
        // rule function can accept argument:
        // components - components registered to Form mapped by name
        rule: (value, components) => {
            const password = components.password.state;
            const passwordConfirm = components.passwordConfirm.state;
            const isBothUsed = password
                && passwordConfirm
                && password.isUsed
                && passwordConfirm.isUsed;
            const isBothChanged = isBothUsed && password.isChanged && passwordConfirm.isChanged;

            if (!isBothUsed || !isBothChanged) {
                return true;
            }

            return password.value === passwordConfirm.value;
        },
        hint: () => <span className="react-validation-error-text">Passwords should be equal.</span>
    }
});
