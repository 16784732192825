import React, {Component, PropTypes} from 'react';

export default class McLabWebPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>Video Demo of Highlight Functionality</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/ZpeRxS5CeKM?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Overview</h3>
                <p className='portfolio-indented'>
                    McLab-Web is an effort to make the MATLAB tools offered by McGill's <a href="http://www.sable.mcgill.ca/mclab/" target="_blank">McLab Project</a> more
                    accessible to those with less of a technical background. It does so by providing an easy, intuitive interface in a
                    familiar environment: the browser.<br/><br/>During my time working on the project, I streamlined the process of converting
                    MATLAB code into JavaScript by enabling in-editor highlighting and popup hints, as well as exposed a method for future
                    developers to integrate additional React components into the web interface.
                </p>

                <h3>The Process</h3>
                <p className='portfolio-indented'>
                    All of the work done to acquire functioning highlights and popups within McLab-Web's code editor has been documented
                    and is viewable at this <a href="/static/docs/streamlining-mclab-web.pdf" target="_blank">link</a>. Topics include motivation, an example use-case, software architecture, and future work.
                </p>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://nodejs.org/" target="_blank">Node.js</a><br/>
                    <a href="http://expressjs.com/" target="_blank">Express</a><br/>
                    <a href="https://ace.c9.io/" target="_blank">Ace Editor</a><br/>
                    <a href="https://facebook.github.io/react/" target="_blank">React</a><br/>
                    <a href="http://getbootstrap.com/" target="_blank">Bootstrap</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="http://mclab-web.othnielcundangan.me/" target="_blank">View a live copy of the site</a><br/>
                    <a href="https://github.com/Sable/McLab-Web" target="_blank">View the GitHub repo</a><br/>
                    <a href="http://www.sable.mcgill.ca/mclab/" target="_blank">View the McGill project page</a><br/>
                    <a href="/static/docs/streamlining-mclab-web.pdf" target="_blank">View the paper discussing my work on the project</a>
                </p>
            </div>
        );
    }
}
