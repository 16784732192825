import React, {Component, PropTypes} from 'react';

export default class MOMTPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>Video Demo</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/YNrR1-r-AAc?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Overview</h3>
                <p className='portfolio-indented'>
                    Acted as scrum master within a group of 5 programmers.
                    McGill Once McGill Twice was built as a process to learn in a very hands-on manner how to plan, construct, and release a product following agile scrum principles within the team.
                    The game features multiplayer free-roam, a connect-four minigame, chatting capabilities, and a procedural environment.
                    <br/><br/>
                    <b>The Team:</b> Othniel Cundangan, Aliaa Moharram, Tristan Struthers, Ching-Chia Wang (Russell), Elizabeth Wu
                </p>

                <h3>Downloadable Builds</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/McGill-Once-McGill-Twice/releases/download/v0.04-pre-alpha/McGill.Once.McGill.Twice.Mac.zip" target="_blank">Mac</a><br/>
                    <a href="https://github.com/oatssss/McGill-Once-McGill-Twice/releases/download/v0.04-pre-alpha/McGill.Once.McGill.Twice.Windows.zip">Windows</a>
                </p>

                <h3>Scrum Process</h3>
                <p className='portfolio-indented'>
                    We divided work into 7-day sprints with meetings in-between. All the planning up until coding began was documented. Key topics covered in the planning documents include:
                    <br/><br/>
                    Phased breakdown<br/>
                    Dated activity graph<br/>
                    Milestones<br/>
                    Software engineering methods<br/>
                    Risk identification, impact estimation<br/>
                    Deployment diagram<br/>
                    Domain model<br/>
                    Design pattern identification<br/>
                    Coding agreement
                    <br/><br/>
                    You may download the planning documents below:
                    <br/>
                    <a href="/static/docs/momt-prelim-design-doc.pdf" target="_blank">Preliminary Design Document</a><br/>
                    <a href="/static/docs/momt-design-doc.pdf" target="_blank">Design Document</a><br/>
                    <a href="/static/docs/momt-requirements-doc.pdf" target="_blank">Requirements Document</a>
                </p>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://unity3d.com/" target="_blank">Unity3D</a><br/>
                    <a href="https://msdn.microsoft.com/en-ca/library/2fdbz5xd.aspx" target="_blank">C# Programming Language</a><br/>
                    <a href="https://www.blender.org/" target="_blank">Blender3D</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/McGill-Once-McGill-Twice/tree/master/McGill-Once-McGill-Twice/Assets/Resources/Scripts" target="_blank">View the relevant files within the GitHub repo</a><br/>
                    <a href="/static/docs/momt-prelim-design-doc.pdf" target="_blank">View the preliminary design document</a><br/>
                    <a href="/static/docs/momt-design-doc.pdf" target="_blank">View the design document</a><br/>
                    <a href="/static/docs/momt-requirements-doc.pdf" target="_blank">View the requirements document</a>
                </p>
            </div>
        );
    }
}
