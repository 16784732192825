import React, {Component, PropTypes} from 'react';

export default class ArtificialIntelligencePage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>Course Description</h3>
                <p>
                    Taken from the <a href='https://www.mcgill.ca/study/2015-2016/courses/comp-424' target='_blank'>McGill course page</a>
                    <blockquote className='portfolio-indented'><i>
                        Introduction to search methods. Knowledge representation using logic and probability.
                        Planning and decision making under uncertainty. Introduction to machine learning.
                    </i></blockquote>
                </p>

                <h3>Course Project</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/CznArkxOvHA?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Project Description</h3>
                <p className='portfolio-indented'>
                    The course required the construction of our own AI bot capable of playing a game of <a href="http://mancala.wikia.com/wiki/Hus" target="_blank">Hus</a>.
                    The video above demonstrates my bot in action. The bot is fairly simple in that it only searches the
                    tree of possible moves to a depth of 5 to 6 future board states. Each board state has an associated numerical
                    value of how strong/appealing that state is. The move resulting in the best board state is what the bot opts for.
                </p>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://eclipse.org/" target="_blank">Eclipse</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/COMP-424-Artificial-Intelligence/tree/master/comp424_project/src" target="_blank">View the GitHub repo</a><br/>
                    <a href="https://github.com/oatssss/COMP-424-Artificial-Intelligence/releases/download/v1/424-hus-ai-bot.jar" target="_blank">Download a runnable JAR of the game + my bot</a><br/>
                </p>
            </div>
        );
    }
}
