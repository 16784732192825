var SimpleSchema = require('simpl-schema').default;

var emailSchemeOptions = {
    type: String,
    min: 1,
    max: 100,
    regEx: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[A-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?$/,
};

var emailScheme = new SimpleSchema({
    email: emailSchemeOptions,
});

var messageScheme = new SimpleSchema({
    contactName: {
        type: String,
        min: 1,
        max: 100,
    },
    contactMessage: {
        type: String,
        min: 1,
        max: 5000,
    },
    contactEmail: emailSchemeOptions,
    captchaResponse: {
        type: String,
        min: 1,
        max: 1000,
    },
});

module.exports = {
    emailScheme,
    messageScheme,
};