import React, {Component, PropTypes} from 'react';

const gifSrc = '/static/images/comp559_transparent.gif';
const gifStill = '/static/images/comp559_transparent_still.png';

export default class ComputerAnimationPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);

        this.state = {
            imgSrc: gifStill
        }
    }

    componentDidMount() {
        // Start the gif 4 seconds after the page has loaded
        setTimeout(() => this.setState({imgSrc:gifSrc}), 4000);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <img
                    style={{
                        width: '100%',
                        maxWidth: '655px',
                        display: 'block',
                        margin: '0 auto',
                    }}
                    ref={(gif) => this.gif = gif}
                    src={this.state.imgSrc}
                    onMouseOver={() => this.setState({imgSrc:gifStill})}
                    onMouseOut={() => this.setState({imgSrc:gifSrc})}
                />
                <span
                    style={{
                        display: 'block',
                        margin: '0 auto',
                        textAlign:'center',
                        /*textTransform:'uppercase',*/
                        fontSize:'0.7em'
                    }}
                >
                    Taken from <a href="http://cs.mcgill.ca/~kry/comp559W16/dumpfast.gif" target="_blank">http://cs.mcgill.ca/~kry/comp559W16/</a>
                </span>

                <h3>Course Description</h3>
                <span>
                    Taken from the <a href='http://cs.mcgill.ca/~kry/comp559W16/' target='_blank'>McGill course page</a>
                </span>
                <blockquote className='portfolio-indented'><i>
                    This course [provided] an introduction to computational techniques for generating animation.
                    Topics [included] methods which have applications in the creation of movie effects,
                    the development of video games, and training simulations.
                    While the focus [was] largely [on] physically based methods for the automatic generation of motion,
                    part of the course [covered] methods for user control of animation and reuse of captured motion data.
                <br/><br/>
                    The main objective of this course is to have students develop an understanding of fundamental techniques
                    used for computer animation. At the end of the term, students will be able to identify the advantages
                    and disadvantages of using simulation, procedural animation, motion capture, and hand designed animations,
                    with respect to both online and offline applications. [Students] will also be able to implement animation
                    techniques, using common software languages and tools. Finally, in addition to an understanding of current
                    practices in computer animation, a general objective is for students to better recognize current important
                    challenges in computer animation.
                </i></blockquote>

                <h3>Demo Videos</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/rFVij7ijObg?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/utLzrHzVlIg?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://eclipse.org/" target="_blank">Eclipse</a><br/>
                    <a href="https://jogamp.org/jogl/www/" target="_blank">JOGL</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/Fundamentals-of-Computer-Animation" target="_blank">View the GitHub repo</a><br/>
                </p>
            </div>
        );
    }
}
