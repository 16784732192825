import React, {Component, PropTypes} from 'react';

import PortfolioCard from '../components/PortfolioCard.jsx';

export default class WorkExperiencePage extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children, app} = this.props;

        return (
            <div>
                {/*<!-- First Photo Grid-->*/}
                <div className="w3-row-padding portfolio-row">
                    <PortfolioCard
                        app={app}
                        imgSrc='/static/images/globalvision.png'
                        /*url='http://www.globalvisioninc.com/'*/
                        route='/portfolio/work/globalvision'
                    >
                        <p><b>C++ Developer</b> | Global Vision</p>
                        <p>
                            Part-time work with Global Vision's text inspection software.
                            Duties include bug fixing and integrating legacy code with a new generation of inspection software.
                        </p>
                    </PortfolioCard>
                    {/*<div className="w3-third w3-container w3-margin-bottom">
                        <img src="/static/images/lights.jpg" alt="Norway" style={{width:'100%'}} className="w3-hover-opacity"/>
                        <div className="w3-container w3-white">
                            <p><b>Lorem Ipsum</b></p>
                            <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
                        </div>
                    </div>
                    <div className="w3-third w3-container">
                        <img src="/static/images/nature.jpg" alt="Norway" style={{width:'100%'}} className="w3-hover-opacity"/>
                        <div className="w3-container w3-white">
                            <p><b>Lorem Ipsum</b></p>
                            <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
                        </div>
                    </div>*/}
                </div>

                {/*<!-- Second Photo Grid-->*/}
                {/*<div className="w3-row-padding">
                    <div className="w3-third w3-container w3-margin-bottom">
                        <img src="/static/images/p1.jpg" alt="Norway" style={{width:'100%'}} className="w3-hover-opacity"/>
                        <div className="w3-container w3-white">
                            <p><b>Lorem Ipsum</b></p>
                            <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
                        </div>
                    </div>
                    <div className="w3-third w3-container w3-margin-bottom">
                        <img src="/static/images/p2.jpg" alt="Norway" style={{width:'100%'}} className="w3-hover-opacity"/>
                        <div className="w3-container w3-white">
                            <p><b>Lorem Ipsum</b></p>
                            <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
                        </div>
                    </div>
                    <div className="w3-third w3-container">
                        <img src="/static/images/p3.jpg" alt="Norway" style={{width:'100%'}} className="w3-hover-opacity"/>
                        <div className="w3-container w3-white">
                            <p><b>Lorem Ipsum</b></p>
                            <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
                        </div>
                    </div>
                </div>*/}

                {/*<!-- Pagination -->*/}
                {/*<div className="w3-center w3-padding-32">
                    <ul className="w3-pagination">
                        <li><a className="w3-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">1</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">2</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">3</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">4</a></li>
                        <li><a className="w3-hover-black" href="http://www.w3schools.com/w3css/tryw3css_templates_portfolio.htm#">»</a></li>
                    </ul>
                </div>*/}
            </div>
        );
    }
}
