import React, {Component, PropTypes} from 'react';

export default class PortfolioCard extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
        imgSrc: PropTypes.string,
        route: PropTypes.string,
        url: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            opacity: 1,
        };

        this.cursor = props.url || props.route ? 'pointer' : 'auto';
    }

    render() {
        const {
            children,
            app,
            imgSrc,
            route,
            url,
        } = this.props;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: this.cursor,
                }}
                className='w3-third w3-container w3-margin-bottom'
                onMouseEnter={() => this.setState({opacity:0.6})}
                onMouseLeave={() => this.setState({opacity:1})}
                onClick={() => {
                    if (url) {
                        window.open(url, '_blank');
                    }
                    else if (route) {
                        app.navigatePage(route)
                    }
                }}
            >
                <img
                    src={imgSrc}
                    style={{
                        display:'block',
                        opacity:this.state.opacity,
                        margin: 'unset',
                    }}
                    className='w3-hover-opacity portfolio-card-img'
                />
                <div
                    className='w3-container w3-white'
                    style={{
                        flex: '1 0 auto',
                        position: 'relative'
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
}
