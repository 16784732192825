import React, {Component, PropTypes} from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, IndexRoute, Redirect, IndexRedirect, browserHistory} from 'react-router';

// Scripts first
import './scripts/react-validation.js';

import App from './pages/App.jsx';
import PortfolioPage from './pages/PortfolioPage.jsx';
import WorkExperiencePage from './pages/WorkExperiencePage.jsx';
    import GlobalVisionPage from './pages/GlobalVisionPage.jsx';
import DeveloperExperiencePage from './pages/DeveloperExperiencePage.jsx';
    import ThunderSynthesisPage from './pages/ThunderSynthesisPage.jsx';
    import BoidsFlockingPage from './pages/BoidsFlockingPage.jsx';
    import ImageSonificationPage from './pages/ImageSonificationPage.jsx';
    import MOMTPage from './pages/MOMTPage.jsx';
    import UbisoftCompetitionPage from './pages/UbisoftCompetitionPage.jsx';
    import McLabWebPage from './pages/McLabWebPage.jsx';
    import McGillSchedulerPage from './pages/McGillSchedulerPage.jsx';
import DeveloperExperiencePage2 from './pages/DeveloperExperiencePage2.jsx';
import EducationExperiencePage from './pages/EducationExperiencePage.jsx';
    import ComputerGraphicsPage from './pages/ComputerGraphicsPage.jsx';
    import ComputerAnimationPage from './pages/ComputerAnimationPage.jsx';
    import ArtificialIntelligencePage from './pages/ArtificialIntelligencePage.jsx';
import ResumePage from './pages/ResumePage.jsx';
import ContactPage from './pages/ContactPage.jsx';
    import ContactSentPage from './pages/ContactSentPage.jsx';
import Four04Page from './pages/Four04Page.jsx';

ReactDOM.render(
    <Router history={browserHistory}>
        <Route path='/' component={App}>
            <IndexRedirect to='portfolio/projects'/>
            {/*<Route path='portfolio' component={PortfolioPage}/>*/}
            <Redirect from='portfolio' to='portfolio/projects'/>
            <Route path='portfolio/work' component={WorkExperiencePage}/>
                <Route path='portfolio/work/globalvision' component={GlobalVisionPage}/>
            <Route path='portfolio/projects' component={DeveloperExperiencePage}/>
                <Route path='portfolio/projects/boids-flocking' component={BoidsFlockingPage}/>
                <Route path='portfolio/projects/image-sonification' component={ImageSonificationPage}/>
                <Route path='portfolio/projects/thunder-synthesis' component={ThunderSynthesisPage}/>
                <Route path='portfolio/projects/mcgill-once-mcgill-twice' component={MOMTPage}/>
                <Route path='portfolio/projects/ubisoft-competition' component={UbisoftCompetitionPage}/>
                <Route path='portfolio/projects/mclab-web' component={McLabWebPage}/>
                <Route path='portfolio/projects/mcgill-scheduler' component={McGillSchedulerPage}/>
            <Route path='portfolio/projects/2' component={DeveloperExperiencePage2}/>
            <Route path='portfolio/education' component={EducationExperiencePage}/>
                <Route path='/portfolio/education/computer-graphics-course' component={ComputerGraphicsPage}/>
                <Route path='/portfolio/education/computer-animation-course' component={ComputerAnimationPage}/>
                <Route path='/portfolio/education/artificial-intelligence-course' component={ArtificialIntelligencePage}/>
            <Route path='resume' component={ResumePage}/>
            <Route path='contact' component={ContactPage}/>
                <Route path='contact/sent' component={ContactSentPage}/>
            <Route path='*' component={Four04Page}/>
        </Route>
    </Router>
, document.getElementById('app-container'));
