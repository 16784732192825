import React, {Component, PropTypes} from 'react';

export default class ComputerGraphicsPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>Course Description</h3>
                <span>
                    Taken from the <a href='http://cs.mcgill.ca/~kry/comp557F15/' target='_blank'>McGill course page</a>
                </span>
                <blockquote className='portfolio-indented'><i>
                    The study of fundamental mathematical, algorithmic and representational issues in computer graphics.
                    The topics include an overview of graphics pipeline, projective geometry, homogeneous coordinates,
                    projective transformations, quadrics and tensors, line-drawing, surface modeling and object modeling,
                    reflectance models and rendering, texture mapping, polyhedral representations, colour perception,
                    and other selected topics according to available time
                </i></blockquote>

                <h3>Demo Videos</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/8aUiCG-ayl0?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/AhXgM2IxsYE?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/9t-K22K4feg?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/_J3wzyRKjKs?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/e42v5Pu-eFA?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Course Work</h3>
                <p className='portfolio-indented'>
                    The course required the completion of various assignments.
                    I've packaged each assignment as a runnable JAR and they are available at the links below:
                    <ul>
                        <li>
                            <a href="https://github.com/oatssss/Fundamentals-of-Computer-Graphics/releases/download/v1/A1.-.Matrix.Transformations.and.Rotations.zip" target="_blank">Assignment 1 - Matrix Transformations and Rotations</a>
                        </li>
                        <li>
                            <a href="https://github.com/oatssss/Fundamentals-of-Computer-Graphics/releases/download/v1/A2.-.Transform.Hierarchy.for.Animated.Characters.Perspective.Projection.Frustums.and.Anaglyphs.zip" target="_blank">Assignment 2 - Transform Hierarchies, Perspective Projections, and Anaglyphs</a>
                        </li>
                        <li>
                            <a href="https://github.com/oatssss/Fundamentals-of-Computer-Graphics/releases/download/v1/A3.-.Shaders.zip" target="_blank">Assignment 3 - Shaders</a>
                        </li>
                        <li>
                            <a href="https://github.com/oatssss/Fundamentals-of-Computer-Graphics/releases/download/v1/A4.-.Mesh.Simplification.zip" target="_blank">Assignment 4 - Mesh Simplification</a>
                        </li>
                    </ul>
                </p>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://eclipse.org/" target="_blank">Eclipse</a><br/>
                    <a href="https://jogamp.org/jogl/www/" target="_blank">JOGL</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/Fundamentals-of-Computer-Graphics" target="_blank">View the GitHub repo</a><br/>
                    <a href="https://github.com/oatssss/Fundamentals-of-Computer-Graphics/releases" target="_blank">Download runnable JARs from some of my work</a><br/>
                </p>
            </div>
        );
    }
}
