import React, {Component, PropTypes} from 'react';
import {Modal} from 'react-bootstrap';
import Recaptcha from 'react-google-recaptcha';
import httpRequest from 'request';
import Validation from 'react-validation';
import {browserHistory} from 'react-router';

export default class Contact extends Component {
    static propTypes = {
        children: PropTypes.node,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.submitCaptcha = this.submitCaptcha.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);

        this.state = {
            modal: false,
            sendingMessage: false,
            validInputs: false,
        };
    }

    showModal() {
        this.setState({modal:true});
    }

    hideModal() {
        this.setState({modal:false});
    }

    submitCaptcha(captchaResponse, modal) {
        if (!modal) {
            this.props.app.setState({captchaResponse});
            return;
        }

        if (!this.props.app.state.email) {
            const postData = {
                captchaResponse
            };
            httpRequest({
                url: `${document.location.href.split('/').slice(0,3).join('/')}/contact/get-email`,
                method: 'POST',
                json: true,
                body: postData
            }, ((error, response, body) => {
                if (error || response.statusCode >= 300) {

                }
                else {
                    this.hideModal();
                    this.props.app.setState({email:body});
                }
            }).bind(this));
        }
    }

    submitForm(event) {
        event.preventDefault();

        this.setState({sendingMessage:true});

        const {app} = this.props;

        const postData = {
            contactName: app.state.contactName,
            contactEmail: app.state.contactEmail,
            contactMessage: app.state.contactMessage,
            captchaResponse: app.state.captchaResponse,
        };

        httpRequest({
            url: `${document.location.href.split('/').slice(0,3).join('/')}/contact/send-message`,
            method: 'POST',
            json: true,
            body: postData,
        }, ((error, response, body) => {
            // Re-enable the submit button
            this.setState({
                captchaResponse: undefined,
                sendingMessage: false,
            });

            if (error || response.statusCode >= 300) {
                if (body && body.validationErrors) {
                    for (let valErr of body.validationErrors) {
                        const field = Object.keys(valErr)[0];
                        this.form.showError(field, () => <span className='react-validation-error-text'>Server validation error: {valErr.type}</span>);
                    }
                    console.log(body.validationErrors);
                }
                else if (error) {
                    console.log(error);
                }
            }
            else {
                // Clear form inputs and render success page
                app.setState({
                    contactName: '',
                    contactEmail: '',
                    contactMessage: '',
                    // children: null,
                });

                browserHistory.push('/contact/sent');
            }
        }).bind(this));
    }

    handleFormChange(newAppState) {
        const errors = this.form.validateAll();
        if (Object.keys(errors) <= 0) {
            this.setState({validInputs:true});
        } else {
            this.setState({validInputs:false});
        }

        this.props.app.setState(newAppState);
    }

    render() {
        const {children, app} = this.props;

        let showEmail = 'Click to Show Email';
        if (app.state.email) {
            showEmail = <a href={`mailto:${app.state.email}`} target='_top' style={{color:'white'}}>{app.state.email}</a>;
        }

        return (
            <div className="w3-container w3-padding-large">
                <div className="w3-row-padding w3-center w3-padding-24" style={{margin:'0 -16px'}}>
                    <div className="w3-third w3-dark-grey" onClick={app.state.email ? () => {} : this.showModal} style={app.state.email ? {} : {cursor:'pointer'}}>
                        <p><i className="fa fa-envelope w3-xxlarge w3-text-light-grey"/></p>
                        <p>{showEmail}</p>
                    </div>
                    <div className="w3-third w3-teal">
                        <p><i className="fa fa-map-marker w3-xxlarge w3-text-light-grey"/></p>
                        <p>Montreal + Toronto, Canada</p>
                    </div>
                    <div className="w3-third w3-dark-grey">
                        <p><i className="fa fa-phone w3-xxlarge w3-text-light-grey"/></p>
                        <p>438 828 2472</p>
                    </div>
                </div>
                <hr/>
                <h5 style={{textAlign: 'center'}}>You can also fill out the form below to send me a message</h5>
                <Validation.components.Form ref={form => { this.form = form }} onSubmit={this.submitForm}>
                    <div className="w3-group">
                        <label>
                            Your Name
                            <Validation.components.Input
                                onFocus={() => this.form.hideError('name')}
                                type="text"
                                className="w3-input w3-border"
                                containerClassName="react-validation-container"
                                errorClassName="react-validation-error-component"
                                value={app.state.contactName || ''}
                                onChange={(e) => this.handleFormChange({contactName:e.target.value})}
                                name="name"
                                validations={['required']}
                            />
                        </label>
                    </div>
                    <div className="w3-group">
                        <label>
                            Email
                            <Validation.components.Input
                                onFocus={() => this.form.hideError('email')}
                                type="text"
                                className="w3-input w3-border"
                                containerClassName="react-validation-container"
                                errorClassName="react-validation-error-component"
                                value={app.state.contactEmail || ''}
                                onChange={(e) => this.handleFormChange({contactEmail:e.target.value})}
                                name="email"
                                validations={['required', 'email']}
                            />
                        </label>
                    </div>
                    <div className="w3-group">
                        <label>
                            Message
                            <Validation.components.Textarea
                                onFocus={() => this.form.hideError('message')}
                                type="text"
                                className="w3-input w3-border full-width"
                                containerClassName="react-validation-container"
                                errorClassName="react-validation-error-component"
                                value={app.state.contactMessage || ''}
                                onChange={(e) => this.handleFormChange({contactMessage:e.target.value})}
                                name="message"
                                validations={['required']}
                            />
                        </label>
                    </div>
                    <div className="w3-group">
                        <Recaptcha
                            sitekey='6Le0WSATAAAAAB7PIqbx4eY12ck6GdUdo2IdaPSy'
                            onChange={this.submitCaptcha}
                        />
                    </div>
                    <button
                        type="submit"
                        className="w3-btn w3-padding-large w3-margin-bottom"
                        disabled={!this.state.validInputs || !app.state.captchaResponse || this.state.sendingMessage}
                    >
                        <i className="fa fa-paper-plane w3-margin-right"/>Send Message
                    </button>
                </Validation.components.Form>
                <Modal
                    show={this.state.modal}
                    onHide={this.hideModal}
                    dialogClassName='modal-captcha'
                >
                    <Recaptcha
                        sitekey='6Le0WSATAAAAAB7PIqbx4eY12ck6GdUdo2IdaPSy'
                        onChange={(response) => this.submitCaptcha(response, true)}
                    />
                </Modal>
            </div>
        );
    }
}
