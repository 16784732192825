import React, {Component, PropTypes} from 'react';

export default class GlobalVisionPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>Job Description</h3>
                <h6><i>(Taken from the GlobalVision web description of the position)</i></h6>
                <h6><a href="http://www.globalvisioninc.com/" target="_blank">Company Site</a></h6>
                <hr/>
                <div className="blockquote">
                    <h2>Software Developer (C++)</h2>
                    <h4>What We Are Looking For</h4>
                    <p>
                        Are you passionate about software development?
                        Do you have a love for code?
                        GlobalVision is looking for a <b>Software Developer (C++)</b> for the development of
                        new features and maintenance of various software projects.
                    </p>
                    <h4>Who We Are</h4>
                    <p>
                        We are GlobalVision, a software and technology company founded in 1990 and headquartered in Montreal.
                        We develop high-tech industrial quality control solutions for international businesses.
                        We’re a global company and businesses everywhere use and depend on our software.
                        We have a strong vision, and it’s carried out by a tight-knit, talented, and diverse team.
                    </p>
                    <h4>Work Hard, Play Hard</h4>
                    <p>
                        At GlobalVision balance is really important to us.
                        Growing our people is just as important as building the best products possible.
                        We have carefully cultivated our team, and we are always looking for new talent to join our company.
                    </p>
                    <h4>The day-to-day</h4>
                    <ul>
                        <li>Software design, development and implementation.</li>
                        <li>New feature development and algorithms for text and image processing.</li>
                        <li>Software maintenance and improvement to existing products and functionality.</li>
                        <li>Creation of software installers.</li>
                    </ul>
                    <h4>Qualifications</h4>
                    <ul>
                        <li>Bachelor’s degree in Computer Science, Computer Engineering or Software Engineering.</li>
                        <li>2+ years of experience in developing and writing software.</li>
                        <li>Experience developing desktop applications for Windows and MAC.</li>
                        <li>Strong knowledge of C/C++ for Windows (Microsoft Visual Studio), Visual Studio, Office.</li>
                        <li>Strong knowledge of object oriented design, as well as strong mathematical skills.</li>
                        <li>Solid debugging skills with the ability to quickly learn and analyze code.</li>
                        <li>Excellent written and spoken English.</li>
                    </ul>
                    <h4>Nice to have</h4>
                    <ul>
                        <li>Experience with QT, wxWidgets, OpenCV or working with PDF files and structure.</li>
                        <li>Image processing experience.</li>
                        <li>Document processing and OCR knowledge.</li>
                    </ul>
                </div>
            </div>
        );
    }
}
