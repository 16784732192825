import React, {Component, PropTypes} from 'react';

export default class UbisoftCompetitionPage extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {children} = this.props;

        return (
            <div style={{textAlign:'justify',marginBottom:'32px'}} className='portfolio-indented'>
                <h3>The Contest</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/J9_pZXdaVqM?rel=0" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Deep Under (our game)</h3>
                <div className='portfolio-indented outerVideoWrapper'>
                    <div className='videoWrapper'>
                        <iframe width="640" height="360" src="https://www.youtube.com/embed/3nv-r-cFcA0?rel=0?ecver=2" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>

                <h3>Overview</h3>
                <p className='portfolio-indented'>
                    Throughout the competition, my responsibilities included merging artists' assets with program logic (event-driven animation) and integrating my boids simulation into
                    non-playable-character navigation schemes. On the UI end, I constructed the player HUD, in-game pause menu, and asynchronous loading screens. Since I was also the
                    team member with the most experience developing within the Unity game engine, I was consulted on many of the chosen solutions for a variety of challenges ranging from
                    lighting to AI to level creation.
                </p>

                <h3>Downloadable Builds</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/Deep-Under/releases/download/v1/Deep.Under.Mac.zip" target="_blank">Mac</a><br/>
                    <a href="https://github.com/oatssss/Deep-Under/releases/download/v1/Deep.Under.Windows.zip">Windows</a>
                </p>

                <h3>Technology Used</h3>
                <p className='portfolio-indented'>
                    <a href="https://unity3d.com/" target="_blank">Unity3D</a><br/>
                    <a href="https://msdn.microsoft.com/en-ca/library/2fdbz5xd.aspx" target="_blank">C# Programming Language</a><br/>
                    <a href="https://www.blender.org/" target="_blank">Blender3D</a><br/>
                    <a href="https://www.maxon.net/en/products/cinema-4d/overview/" target="_blank">Cinema 4D</a><br/>
                    <a href="https://github.com/oatssss" target="_blank">GitHub</a> / <a href="https://git-scm.com/" target="_blank">git</a><br/>
                </p>

                <h3>Code and Links</h3>
                <p className='portfolio-indented'>
                    <a href="https://github.com/oatssss/Deep-Under/tree/master/Deep%20Under/Assets/AI/Scripts" target="_blank">View the relevant files within the GitHub repo</a><br/>
                </p>
            </div>
        );
    }
}
